@import url("https://fonts.googleapis.com/css2?family=Roboto+Serif:opsz,wght@8..144,300;8..144,400;8..144,500;8..144,600;8..144,700;8..144,800;8..144,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Roboto Serif", serif;
}

@layer components {
  .px_layout {
    @apply sm:px-[60px] xs:px-[40px] px-[20px];
  }
  .topsec_img {
    @apply xs:h-[3em] h-[4em] aspect-auto min-w-[4em];
  }
  .navbar {
    @apply flex flex-row w-full justify-between xl:text-[16px] text-[12px] items-center;
  }

  .navbar_opt {
    @apply flex justify-center items-center hover:bg-gray-200 min-h-[3em] grow-[1] px-[5px] text-center;
  }
  .drop_navbar {
    @apply absolute right-0 top-[100%] flex flex-col border-[2px] rounded-[5px] border-gray-800 overflow-hidden bg-gray-700 xs:min-w-[200px] shadow-md;
  }
  .drop_navbar_opt {
    @apply text-tertiary hover:bg-primary hover:text-secondary px-[2em] py-[0.5em] w-full text-[1.1em] rounded-[5px];
  }
  .nav_toggler {
    @apply aspect-square h-max p-[4px] border-[2px] hover:border-primary border-secondary rounded-[5px] text-primary text-[1.2em] hover:bg-secondary;
  }
  /* ------------------------------------------------------------------------ */
  .curve_start {
    border-top-left-radius: 50% 15vw;
    border-top-right-radius: 50% 15vw;
  }
  .curve_end {
    border-bottom-left-radius: 50% 15vw;
    border-bottom-right-radius: 50% 15vw;
    background: #ca1316;
    /* background: #c81013; */
  }
  .size_text {
    @apply 2xl:text-[24px] md:text-[20px] 2xs:text-[16px] text-[10px];
  }
  .no_bubble_layout {
    @apply py-[3vw] px-[5%] size_text;
  }
  .bubble_layout {
    @apply bg-secondary md:min-h-[500px] min-h-[250px] curve_start curve_end mt-[3vw] sm:mb-[3vw] size_text;
  }
  /* ------------------------------------------------------------------------ */
  .data_table:is(table, tr, td, th) {
    @apply border-[2px] border-gray-200 px-[1em] py-[0.2em] text-[1em];
  }
  .data_table table:first-of-type th {
    @apply rounded-t-[1.2em] overflow-hidden;
  }
  .data_table table th {
    @apply py-[0.5em];
  }

  .map {
    @apply max-w-[500px] w-[100%] aspect-[1/1];
  }
  .table_mobile_header {
    @apply sm:hidden text-[0.8em] text-secondary font-semibold;
  }
  .disabled_btn {
    @apply bg-gray-200 text-gray-500;
  }
  .primary_btn {
    @apply hover:bg-secondary bg-primary text-secondary hover:text-tertiary;
  }
  .tertiary_btn {
    @apply bg-tertiary text-secondary hover:bg-primary hover:text-secondary;
  }
  .secondary_btn {
    @apply bg-secondary text-tertiary hover:bg-secondary/80 hover:text-tertiary;
  }
  .call_papers_list {
    @apply w-[95%] ml-auto list-disc list-outside flex flex-col gap-[1em] pt-[1em] text-[0.9em];
  }
  .social_link {
    @apply px-[0.2em] py-[0.05em] aspect-square rounded-full bg-gray-200 text-gray-800 hover:shadow-md hover:shadow-white/80 hover:scale-[1.1] duration-75 hover:bg-white;
  }
  .email {
    @apply text-[0.8em] flex sm:flex-row flex-col gap-x-[1em] gap-y-[0.2em] items-center;
  }
  .email p {
    @apply sm:text-start text-center;
  }
}
